import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useMain } from "../../context/main";
import {
  createHotel,
  eraseBooking,
  fetchAllBookings,
  getBookings,
  getUser,
  getUsers,
  updateBookingStatus,
} from "../../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AdminImage from "../../img/home/Horizon_47.jpg";
import Fade from "react-reveal/Fade";
import dateFormat from "dateformat";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function AdminPage() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { setPending, pending } = useMain();
  const user = JSON.parse(localStorage.getItem("horizonUser"));
  const [admin, setAdmin] = useState(null);
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [users, setUsers] = useState([]);
  const [bookings, setBookings] = useState([]);
  const statuscolors = {
    pending: "#808080",
    confirmed: "#3c9966",
    "client-canceled": "#eb4848",
    canceled: "#e93e27",
  };
  const fetchBookings = async () => {
    try {
      setPending(true);
      const res = await getBookings();
      if (res?.success) {
        // console.log(res);
        setPending(false);
        setBookings(res.bookings);
      } else {
        setPending(false);
        toast.warn("Erreur! Verifier votre connexion");
      }
    } catch (error) {
      setPending(false);
      // console.log(error);
      toast.error(error.message || "Erreur, Verifier votre connection");
    }
  };

  const FetchUsers = async () => {
    try {
      const res = await getUsers();
      if (res?.success) {
        setUsers(res?.users);
        // console.log(res);
      } else {
        // console.log(res);
        toast.warn(
          res?.message ||
            "Erreur! Serveur indisponible, verifier votre connexion"
        );
      }
    } catch (error) {
      toast.error(error.message || "Erreur, Verifier votre connection");
    }
  };

  // 150 - 1 -> Suit Junior
  // 140 - 1
  // 100 - 1
  // 90 - 1
  // 80 - 1
  // 70 -> 4 -> 2 lits

  function openModal(index) {
    setIsOpen(true);
    setTab(index);
  }
  function CloseModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    let admin = JSON.parse(sessionStorage.getItem("admin-access"));

    if (admin?.isAdmin) {
      if (user?.role === "admin" || user?.role === "dev") {
        setAdmin(user);
      } else {
        toast.warn("Erreur! Pas d'autorisation, Veuillez-vous reconnecter!");
        navigate("/");
      }
    } else {
      toast.warn("Erreur! Pas d'autorisation, Veuillez-vous reconnecter!");
      sessionStorage.clear();
      navigate("/");
    }

    fetchBookings();
    FetchUsers();
  }, []);

  const handleChange = async (bkg, value) => {
    // update boooking status
    try {
      setPending(true);
      const res = await updateBookingStatus(bkg._id, value);
      if (res?.success) {
        // setUsers(res?.users);
        fetchBookings();
        setPending(false);
        // console.log(res);
      } else {
        setPending(false);
        // console.log(res);
        toast.warn(
          res?.message ||
            "Erreur! Serveur indisponible, verifier votre connexion"
        );
      }
    } catch (error) {
      setPending(false);
      toast.error(error.message || "Erreur, Verifier votre connection");
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    // console.log(id);
    try {
      setPending(true);
      const res = await eraseBooking(id);
      if (res?.success) {
        setPending(false);
        // console.log(res, "here");
        toast.success("Reservation Supprimée");
        // console.log(res);
        fetchBookings();
      } else {
        toast.warn("Erreur! Verifier votre connexion");
        setPending(false);
      }
    } catch (error) {
      setPending(false);
      toast.error(error.message || error || "Erreur");
    }
  };

  return (
    <>
      <div className="Dining">
        <Fade top>
          <header
            className="header-main"
            style={{
              background: ` no-repeat center/cover url(${AdminImage})`,
              maxHeight: "400px",
            }}
          >
            <div className="header-content">
              <h2 className="alt-font">Admin/Dashboard</h2>
            </div>
          </header>
          {admin ? (
            <div
              style={{
                display: "grid",
                justifyContent: "space-around",
                margin: "12px",
              }}
            >
              <div className="center-inside">
                <button
                  onClick={() => openModal(1)}
                  className="btn alt-font"
                  style={{
                    margin: "6px",
                    fontSize: "17px",
                    textTransform: "uppercase",
                    border: "1px solid black",
                    fontWeight: "bold",
                  }}
                >
                  <i className="fas fa-eye"></i> Utilisateurs
                </button>
              </div>
              <div className="center-inside">
                <button
                  onClick={() => openModal(4)}
                  className="btn alt-font"
                  style={{
                    margin: "6px",
                    fontSize: "17px",
                    textTransform: "uppercase",
                    border: "1px solid black",
                    fontWeight: "bold",
                  }}
                >
                  <i className="fas fa-eye"></i> Bookings
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={CloseModal}
            style={customStyles}
            contentLabel="ADMIN - HOTEL HORIZON"
            ariaHideApp={false}
          >
            <div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <i onClick={CloseModal} className="fa fa-x"></i>
              </div>
              <div className="admin-modal-tables">
                {/* {tab === 0 && <h1>Liste d'utilisateurs</h1>} */}
                {tab === 1 && (
                  <div
                    style={{
                      overflowY: "auto",
                    //   maxHeight: "90vh",
                    //   maxWidth: "95vw",
                      width :"100%",
                      height: "100%",
                      overflowX: "auto",
                    }}
                  >
                    <table>
                      <thead>
                        <td>N°</td>
                        <td>ID</td>
                        <td>username</td>
                        <td>email</td>
                        <td>role</td>
                        <td></td>
                        <td></td>
                      </thead>
                      <tbody>
                        {users.map((item, index) => {
                          return (
                            <tr key={item._id}>
                              <td>{index + 1}</td>
                              <td>{item._id}</td>
                              <td>{item?.username}</td>
                              <td>{item?.email}</td>
                              <td>{item?.role}</td>
                              <td></td>
                              <td>
                                {/* <button
                                  disabled={pending}
                                  onClick={(e) => handleDelete(e, item?._id)}
                                  style={{
                                    background: "#a32e2e",
                                    cursor: "pointer",
                                    border: "none",
                                    paddingInline: "12px",
                                    paddingBlock: "6px",
                                    color: "white",
                                    fontWeight: "bold",
                                    borderRadius: "13px",
                                  }}
                                >
                                  supprimé
                                </button> */}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
                {tab === 4 && (
                  <div>
                    <table
                      style={{
                        // maxHeight: "90vw",
                        overflowY: "scroll",
                        overflowX: "scroll",
                        // width: "100%"
                      }}
                    >
                      <thead>
                        <td>N°</td>
                        <td>ID</td>
                        <td>nom</td>
                        <td>Contact</td>
                        <td>email</td>
                        <td>adultes&enfants</td>
                        <td>check-in</td>
                        <td>check-out</td>
                        <td>chambre-type</td>
                        <td>paiement</td>
                        <td>price</td>
                        <td>total</td>
                        <td>status</td>
                        <td>update</td>
                        <td></td>
                      </thead>
                      <tbody>
                        {bookings.map((item, index) => {
                          return (
                            <tr key={item.confirmation}>
                              <td>{index + 1}</td>
                              <td>{item?.confirmation}</td>
                              <td>
                                {item.firstName}-{item.lastName}
                              </td>
                              <td>
                                <a href={item.phone}>{item.phone}</a>
                              </td>
                              <td>
                                <a href={`mailto:${item.email}`}>
                                  {item.email}
                                </a>
                              </td>
                              <td>
                                {item.adults} -- {item.children}
                              </td>
                              <td>
                                {dateFormat(
                                  new Date(item.startDate),
                                  "dd/mm/yyyy"
                                )}
                              </td>
                              <td>
                                {dateFormat(
                                  new Date(item.endDate),
                                  "dd/mm/yyyy"
                                )}
                              </td>
                              <td>{item.room}</td>
                              <td>{item.cardType}</td>
                              <td>{item.price} $</td>
                              <td>{item.totalPrice} $</td>

                              <td>
                                <span
                                  style={{
                                    backgroundColor: statuscolors[item.status],
                                    color: "black",
                                    paddingInline: "12px",
                                    paddingBlock: "8px",
                                    fontWeight: "bold",
                                    borderRadius: "12px",
                                    border: "1px solid black",
                                  }}
                                >
                                  {item.status}
                                </span>
                              </td>
                              <td>
                                <select
                                  value={item.value}
                                  onChange={(e) =>
                                    handleChange(item, e.target.value)
                                  }
                                >
                                  <option value={item.status}>
                                    {item.status}
                                  </option>
                                  <option value="confirmed">confirmer</option>
                                  <option value="pending">
                                    En Attente(pending)
                                  </option>
                                  <option value="client-canceled">
                                    Annulé (client)
                                  </option>
                                  <option value="canceled">Annulé</option>
                                </select>
                              </td>
                              <td>
                                <button
                                  onClick={(e)=>handleDelete(e, item?.confirmation)}
                                  style={{
                                    background: "#a32e2e",
                                    cursor: "pointer",
                                    border: "none",
                                    paddingInline: "12px",
                                    paddingBlock: "6px",
                                    color: "white",
                                    fontWeight: "bold",
                                    borderRadius: "13px",
                                  }}
                                >
                                  supprimé
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </Fade>
      </div>
    </>
  );
}

export default AdminPage;
