import { createContext, useContext, useState, useEffect } from "react";

const MainContext = createContext();

function MainProvider({children}){
    const [pending, setPending] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const localuser = JSON.parse(sessionStorage.getItem("horizonUser"))
        if(localuser)
            setUser(localuser)
    }, [])
    return (
        <MainContext.Provider value={{pending, setPending, user, setUser}}>
            {children}
        </MainContext.Provider>
    );
}
export const useMain = () => useContext(MainContext);

export default MainProvider;

