import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import "../styles/App.scss"
import emailjs from 'emailjs-com'
import { useMain } from '../context/main';
import { toast } from 'react-toastify';
import Fade from 'react-reveal/Fade';

function ContactSection() {
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const myform = useRef();
    const [t, i18n] = useTranslation("global");
    const {setPending} = useMain();

    const sendEmail = (e) => {
        e.preventDefault();
        setPending(true)
        // toast.success("hello");
        // return
        // console.log("working")
        // setLoading(true)
        emailjs.sendForm('service_w62x3ks', 'template_uvozkvb', myform.current, 'I8bHGYASdznfSirqM')
          .then((result) => {
            setPending(false)
            toast.success(t("notification.sent"), {theme: "dark"})
            setEmail("");
            setName("");
            setMessage("");
            setNumber("");
          }).catch((err) => {
            // console.log(err)
            // setLoading(false)
            setPending(false);
            toast.error(err.text);
          });
      };

    return (
        <Fade bottom>
            
              <div style={{display:"flex", justifyContent: "center"}}>
              <section style={{width: "100%", maxWidth: "500px", margin: "0px", padding: "0px"}}>
                  <form onSubmit={sendEmail} ref={myform} className="contact-form" style={{margin: "0px"}}>
                    <div className="title">{t("contact.contact")}</div>
                    <input value={name} name="from_name"  onChange={(e) => setName(e.target.value)} type="text" placeholder={t("contact.name")} className="input" required/>
                    <input value={email} name="from_email"  onChange={(e) => setEmail(e.target.value)} type="email" placeholder={t("contact.email")} className="input" required/>
                    <input value={number} name="from_number"  onChange={(e) => setNumber(e.target.value)} type="number" placeholder={t("contact.number")} className="input" />
                    <textarea value={message}  name="message" onChange={(e) => setMessage(e.target.value)} placeholder={t("contact.message")} required></textarea>
                    
                    <button type="submit" style={{textTransform: "capitalize"}}>{t("contact.submit")}</button>
                </form>
            </section>
              </div>
        </Fade>
    )
}

export default ContactSection
