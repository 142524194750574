import React from "react";
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade';

const About = () => {
  const [t, i18n] = useTranslation("global")
  return (
    <div className="About">
      <Fade top>
      <header
        className="header-main"
        style={{
          background: ' no-repeat center/cover url("/img/404/404.jpg")',
        }}
      >
        <div className="header-content">
          <h2 className="alt-font">{t("notfound.title")}</h2>
        </div>
      </header>
      </Fade>
      <Fade right>
      <section className="desc">
        <h2 style={{ minHeight: "50vh" }}>
          {t("notfound.desc")}
        </h2>
      </section>
      </Fade>
    </div>
  );
};

export default About;
