import axios from "axios";
import { toast } from "react-toastify";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
}); // can set a base url here

// AUTH
export const login = async (body) => {
  try{
    const {data} = await API.post("api/auth/signin", body);
    if (data){
      return data;
    }
  }catch(error){
    // console.log(error);
    // toast.error(error?.message || "Erreur");
    return error?.message || "Erreur"
  }
}

export const register = async (body) => {
  try{
    const {data} = await API.post("api/auth/signup", body)
    if (data)
      return data;
  }catch(error){
    return error?.message || "Erreur"

  }
}

export const newBooking = async (body) => {
  try{
    const {data} = await API.post("api/bookings/create", body)
    if (data)
      return data;
  }catch(error){
    return error?.message || "Erreur"

  }
}

export const updateRole = async (id, body) => {
  try{
    const {data} = await API.post(`api/auth/update-role/${id}`, body)
    if (data)
      return data;
  }catch(error){
    return error?.message || "Erreur"

  }
}

export const getUser = async (id) => {
  try{
    const {data} = await API.get(`api/auth/user/${id}`)
    if (data)
      return data;
  }catch(error){
    return error?.message || "Erreur"

  }
}

export const getUsers = async (id) => {
  try{
    const {data} = await API.get(`api/auth/users`)
    if (data)
      return data;
  }catch(error){
    return error?.message || "Erreur"

  }
}

export const createHotel = async (hotel) => {
  try{
    const {data} = await API.post("api/hotels/", hotel, {withCredentials: true})
    if (data)
      return data;
  }catch(error){
    console.log(error);
    toast.error("Erreur", error);
    return null

  }
}

export const getBookings = async () => {
  try{
    const {data} = await API.get("api/bookings/bookings")
    if (data)
      return data;
  }catch(error){
    // console.log(error);
    toast.error("Erreur", error);
    return null

  }
}

export const getBooking = async (id) => {
  try{
    const {data} = await API.get(`api/bookings/booking/${id}`)
    if (data)
      return data;
  }catch(error){
    // console.log(error);
    toast.error("Erreur", error);
    return null

  }
}

export const getMyBooking = async (email) => {
  try{
    const {data} = await API.get(`api/bookings/mybookings/${email}`)
    if (data)
      return data;
  }catch(error){
    // console.log(error);
    toast.error("Erreur", error);
    console.log(error);
    return null

  }
}

export const updateBookingStatus = async (id, status) => {
  try{
    const {data} = await API.post(`api/bookings/update-status/${id}/${status}`);
    if (data)
      return data;
  }catch(error){
    // console.log(error);
    toast.error("Erreur", error);
    console.log(error);
    return null

  }
}

export const eraseBooking = async (id) => {
  try{
    const {data} = await API.post(`api/bookings/delete`, {id});
    if (data)
      return data;
  }catch(error){
    // console.log(error);
    toast.error("Erreur", error);
    console.log(error);
    return null

  }
}

export const testConnection = () => API.get("/");
export const fetchRooms = () => API.get("/rooms");
export const fetchRoom = (url) => API.post(`/rooms/${url}`);
export const fetchAvailableRooms = (data) =>
  API.post("/bookings/available", data);
export const fetchAllBookings = () => API.get("/bookings");
export const fetchExistingBooking = (data) => API.post("/bookings", data);
export const createBooking = (data) => API.post("/bookings/create", data);
export const deleteBooking = (data) => API.post("/bookings/delete", data);
export const signin = (data) => API.post("/auth", data);
