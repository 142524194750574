import { useState } from "react";
import { Link } from "react-router-dom";
import BookWidget from "../booking/BookWidget";
import BG1 from "../../img/home/DSC06552-HDR.jpg";
import BG2 from "../../img/home/Horizon_38.jpg";
import BG3 from "../../img/home/DSC06539-HDR.jpg";
import BG4 from "../../img/home/DSC06418.jpg";
import BG5 from "../../img/home/Horizon_5.jpg";
import "../../styles/Home.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";
import HOME1 from "../../img/home/DSC06575.jpg";
import RoomImage from "../../img/home/Horizon_46.jpg";
import RestoImage from "../../img/home/Horizon_12.jpg";
import EventImage from "../../img/home/Horizon_1.jpg";
import Fade from "react-reveal/Fade";

const Home = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className="Home">
      <Fade top>
        <header className="header-main">
          <Carousel
            emulateTouch={true}
            autoPlay={true}
            infiniteLoop={true}
            interval={7000}
            showThumbs={false}
            transitionTime={1000}
          >
            <div>
              <img className="carousel-image" src={BG1} alt="Hotel Horizon" />
            </div>
            <div>
              <img className="carousel-image" src={BG2} alt="Hotel Horizon" />
            </div>
            <div>
              <img className="carousel-image" src={BG3} alt="Hotel Horizon" />
            </div>
            <div>
              <img className="carousel-image" src={BG4} alt="Hotel Horizon" />
            </div>
            <div>
              <img className="carousel-image" src={BG5} alt="Hotel Horizon" />
            </div>
          </Carousel>
          <BookWidget />
        </header>
      </Fade>

      <Fade right>
        <section className="desc" style={{ zIndex: "0" }}>
          <h1 className="alt-font">{t("about.welcome")}</h1>
          <p>{t("about.detail")}</p>
        </section>
      </Fade>
      <Fade left>
        <section style={{marginTop: "20px"}}>
          {/* <article className="descLeft">
            <div className="bg-light"></div>
            <h1 className="alt-font">{t("about.paragraphTitle")}</h1>
            <p>{t("about.paragraph")}</p>
            <h2>{t("about.open")}</h2>
            <Link to="/rooms">
              <button className="btn contrast">{t("more")}</button>
            </Link>
          </article> */}
          <div className="" style={{display: "flex", justifyContent: "center", }}>
            <img src={HOME1} style={{width: "80%", maxHeight: "400px", objectFit: "cover", }} alt="home_resort" />
          </div>
        </section>
      </Fade>
      <Fade bottom>
        <section className="spotlight">
          <h1 className="alt-font">{t("about.spotlight")}</h1>
          <div className="card-row">
            <div className="card">
              <div>
                <img src={RestoImage} alt="home_food" />
              </div>
              <article>
                <h2 className="alt-font">Restauration</h2>
                <p>{t("about.cuisineDetail")}</p>
                <Link to="/dining">
                  <button className="btn contrast">{t("more")}</button>
                </Link>
              </article>
            </div>
            <div className="card">
              <div>
                <img src={RoomImage} alt="home_room" />
              </div>
              <article>
                <h2 className="alt-font">{t("about.room")}</h2>
                <p>{t("about.roomDetail")}</p>
                <Link to="/rooms">
                  <button className="btn contrast">{t("more")}</button>
                </Link>
              </article>
            </div>
            <div className="card">
              <div>
                <img src={EventImage} alt="home_food" />
              </div>
              <article>
                <h2 className="alt-font">{t("about.event")}</h2>
                <p>{t("about.eventDetail")}</p>
                <Link to="/tours">
                  <button className="btn contrast">{t("more")}</button>
                </Link>
              </article>
            </div>
          </div>
        </section>
      </Fade>

      
      {/* <div data-romw-token="z8BfiKZQTU6Nz4Azl1ur041X2rOqpYCuKGvaE2fyBwMLLbW5yt"></div> */}
      <Fade bottom>
        <div className="center-inside">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15944.11946980991!2d28.8778156!3d-2.4970451!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19c29747d383f12d%3A0x6acfb67fedbda39b!2sHorizon%20Hotel%2FBukavu!5e0!3m2!1sen!2scd!4v1714653874001!5m2!1sen!2scd"
            width="100%"
            // height="450"
            
            style={{border: "1px solid black", margin: "20px", minHeight: "300px"}}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </Fade>
      <div data-romw-token="vVSqxT3Pz2pXSt4i7j8LSdcU1HqVSId10uDOQgwP403jPoXxjq"></div>

    </div>
  );
};

export default Home;
