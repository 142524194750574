import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import "../styles/App.scss";
import emailjs from 'emailjs-com';
import { useMain } from '../context/main';
import { toast } from 'react-toastify';
import Fade from 'react-reveal/Fade';
import {useNavigate} from 'react-router-dom'

function EventBookingSection() {
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const [eventType, setEventType] = useState("");
    const [message, setMessage] = useState("");
    const myform = useRef();
    const [t, i18n] = useTranslation("global");
    const {setPending} = useMain();
    const navigate = useNavigate()

    const sendEmail = (e) => {
        e.preventDefault();
        setPending(true)
        // toast.success("hello");
        // return
        // console.log("working")
        // setLoading(true)
        emailjs.sendForm('service_w62x3ks', 'template_h7grjzl', myform.current, 'I8bHGYASdznfSirqM')
          .then((result) => {
            setPending(false)
            toast.success(t("notification.sent") + ", vous allez recevoir un mail pour la confirmation de votre reservation.", {theme: "dark"})
            setEmail("");
            setName("");
            setMessage("");
            setNumber("");
            setEventType("");
            navigate("/")
          }).catch((err) => {
            // console.log(err)
            // setLoading(false)
            setPending(false);
            toast.error(err.text);
          });
      };

    return (
        <Fade bottom>
            
              <div style={{display:"flex", justifyContent: "center"}}>
              <section style={{width: "100%", maxWidth: "500px", margin: "0px", padding: "0px"}}>
                  <form onSubmit={sendEmail} ref={myform} className="contact-form" style={{margin: "0px"}}>
                    <div className="title">{t("event.reservation")}</div>
                    <input value={name} name="from_name"  onChange={(e) => setName(e.target.value)} type="text" placeholder={t("contact.name")} className="input" required/>
                    <input value={email} name="from_email"  onChange={(e) => setEmail(e.target.value)} type="email" placeholder={t("contact.email")} className="input" required/>
                    <input value={number} name="from_number"  onChange={(e) => setNumber(e.target.value)} type="number" placeholder={t("contact.number")} className="input" />
                    <select style={{padding: "8px 12px", fontFamily: "Playfair Display", fontWeight: 'bold', color: "gray", width: '100%', fontSize: "18px"}} value={eventType} name="from_type"  onChange={(e) => setEventType(e.target.value)} className="input" required>
                        <option value="">{t("event.empty")}</option>
                        <option style={{color: "black"}} value="Privatisation du jardin">{t("event.gardenTitle")}</option>
                        <option style={{color: "black"}} value="conference et reunion">{t("event.title1")}</option>
                    </select>

                    <textarea value={message}  name="message" onChange={(e) => setMessage(e.target.value)} placeholder={t("event.formdesc")} required></textarea>
                    
                    <button type="submit" style={{textTransform: "capitalize"}}>{t("contact.submit")}</button>
                </form>
            </section>
              </div>
        </Fade>
    )
}

export default EventBookingSection
