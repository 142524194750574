import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { addDays, addWeeks } from "date-fns";
import TextField from "@material-ui/core/TextField";
import DateRangePicker from "@material-ui/lab/DateRangePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import Box from "@material-ui/core/Box";
import { getAllAvailable, postBookingDetails } from "../../actions/booking";
import "../../styles/BookWidget.scss";
import { useTranslation } from "react-i18next";
import Fade from 'react-reveal/Fade'
import { toast } from "react-toastify";

/* Have to redo redux, store local form here and ONLY update onSubmission... */

const BookWidget = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const [showInfo, setShowInfo] = useState(false);
  const [formData, setFormData] = useState({
    dates: [new Date(), addDays(new Date(), 7)],
    adults: 1,
    children: 0,
  });
  const [t, i18n] = useTranslation("global")
  const toggleMobileDisplay = () => {
    setShowInfo(!showInfo);
  };

  function getWeeksAfter(date, amount) {
    return date ? addWeeks(date, amount) : undefined;
  }

  const updateAdultQuantity = (val) => {
    if (formData.adults === 1 && val === -1) return;
    if (formData.adults === 5 && val === 1) return;
    setFormData({ ...formData, adults: formData.adults + val });
  };
  const updateChildrenQuantity = (val) => {
    if (formData.children === 0 && val === -1) return;
    if (formData.children === 5 && val === 1) return;
    setFormData({ ...formData, children: formData.children + val });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { adults, children, dates } = formData;
    // if (location.pathname !== "/booking") {
    //   history("/booking");
    // }
    // console.log(formData)

    if(Number(adults) < 0){
      return toast.warn("Formulaire erreur")
    }else if (Number(children) < 0){
      return toast.warn("Formulaire erreur")
    }else if (Number(adults) === 0 && Number(children) === 0){
      return toast.warn("Formulaire erreur (adults, children)")
    }
    else if(dates[0] === ""){
      return toast.error("Erreur")
    }else if(dates[1] === ""){
      return toast.error("Erreur")
    }
    // console.log({adults, children, dates});
    history("/booking", {
      state: {adults, children, dates}
    });
    // dispatch(getAllAvailable({ adults, children, dates }));
    // // have to include this information, or retrieve it from the state, to the create booking request
    // dispatch(postBookingDetails({ adults, children, dates }));
  };
  return (
    <div className={`BookWidget ${showInfo ? "active" : ""}`} style={{opacity: "0.9", background: "white"}}>
      <form onSubmit={handleSubmit}>
        <div className="date">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              disablePast
              maxDate={getWeeksAfter(formData.dates[0], 4)}
              startText={t("booking.checkin")}
              endText={t("booking.checkout")}
              value={formData.dates}
              onChange={(newValue) => {
                if (!newValue.includes(null)) {
                  setFormData({
                    ...formData,
                    dates: newValue,
                  });
                }
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> {t("booking.to")} </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="guest" style={{zIndex: "9999"}}>
          <div className="adults">
            <label>{t("booking.adults")}</label>
            <div className="guest-select">
              <div
                className="btn-sm contrast"
                name="adults"
                onClick={() => updateAdultQuantity(1)}
              >
                <i className="fas fa-plus"></i>
              </div>
              <span>{formData.adults}</span>
              <div
                className="btn-sm contrast"
                name="adults"
                onClick={() => {
                  updateAdultQuantity(-1);
                }}
              >
                <i className="fas fa-minus"></i>
              </div>
            </div>
          </div>
          <div className="children">
            <label>{t("booking.childrens")}</label>
            <div className="guest-select">
              <div
                className="btn-sm contrast"
                name="children"
                onClick={() => {
                  updateChildrenQuantity(1);
                }}
              >
                <i className="fas fa-plus"></i>
              </div>
              <span>{formData.children}</span>
              <div
                className="btn-sm contrast"
                name="children"
                onClick={() => {
                  updateChildrenQuantity(-1);
                }}
              >
                <i className="fas fa-minus"></i>
              </div>
            </div>
          </div>
        </div>

        <button type="submit" className="btn">{t("booking.check")}</button>
        <i
          className={`mobile-toggle fas fa-chevron-${showInfo ? "up" : "down"}`}
          onClick={toggleMobileDisplay}
        ></i>
      </form>
    </div>
  );
};

export default BookWidget;
