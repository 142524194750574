import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../styles/Navbar.scss";
import { useTranslation } from "react-i18next";
// import US from '../../assets/flags/US.svg';
// import FR from '../../assets/flags/FR.svg';
import ImageTest from "../../img/home/DSC06418.jpg";
import Fade from 'react-reveal/Fade'

const Navbar = () => {
  const [header, setHeader] = useState("");
  const [t, i18n] = useTranslation("global");

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("");
    } else if (window.scrollY > 70) {
      return setHeader("alt-color");
    }
  };

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  return (
    <Fade top>
      <div className={`Navbar ${header}`}>
      <div className="navTop">
        <div className="contact">
          <i className="fas fa-phone-alt"></i>
          <i className="fas fa-envelope"></i>
          <i className="fab fa-instagram"></i>
          <i className="fab fa-facebook-square"></i> 
        </div>
        <div>
          <Link to="/">
            <h1 className="alt-font">HOTEL HORIZON</h1>
          </Link>
        </div>
        <div>
        <div style={{display: "flex", alignItems: "center"}}>
          <img onClick={() => handleChangeLanguage("fr")} src="flags/FR.svg" title="Francais" className="flag-section" alt="(fr)"/>
          <img onClick={() => handleChangeLanguage("en")} src="flags/US.svg" title="English" className="flag-section" alt="(en)"/>
          <Link to="/login">
          <i className="fas fa-user" style={{margin: "10px"}}></i>
          </Link>
          <Link to="/rooms">
            <button className="btn">{t("header.booking")}</button>
          </Link>
          
        </div>

        </div>
      </div>
      <div className="navBottom">
        <Link to="/rooms">
          {t("header.links.room")} <span>&#183;</span>
        </Link>
        <Link to="/dining">
          {t("header.links.dining")} <span>&#183;</span>{" "}
        </Link>
        <Link to="/tours">
          {t("header.links.tours")} <span>&#183;</span>{" "}
        </Link>
        <Link to="/events">
          {t("header.links.event")} <span>&#183;</span>{" "}
        </Link>
        <Link to="/gallery">
          {t("header.links.gallery")} <span>&#183;</span>{" "}
        </Link>
          <Link to="/about">{t("header.links.about")}</Link>
      </div>
    </div>
    </Fade>
  );
};

export default Navbar;
