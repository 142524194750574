import React, { Fragment, useEffect } from "react";
import "../../../styles/Rooms.scss";
import { getAllRooms } from "../../../actions/rooms";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BookWidget from "../../booking/BookWidget";
import Loader from "../../helper/Loader";
import RoomsBG from '../../../img/home/Horizon_47.jpg'
import { useTranslation } from "react-i18next";
import roomData from "./roomData.js";
import { Carousel } from "react-responsive-carousel";
/* import RoomsJSON from "../../../rooms.json"; */
const Rooms = () => {
  const location = useLocation();
  const [t, i18n] = useTranslation("global");
  const rooms = roomData;

  return (
    <div className="Rooms">
      <header
        className="header-main"
        style={{
          background:
            ` no-repeat center/cover url(${RoomsBG})`,
        }}
      >
        <div className="header-content">
          {/* <h2 className="alt-font">{t("room.title")}</h2>
          <p>
            {t("room.desc")}
          </p> */}
        </div>

        <BookWidget />
      </header>

      <section className="desc">
        <h1 className="alt-font">{t("room.title1")}</h1>
        <p>
          {t("room.desc1")}
        </p>
      </section>

      <section className="flex-row-lg">
        {rooms.length < 1 ? (
          <div className="loader">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <h2>{t("loading")}</h2>
          </div>
        ) : (
          rooms.map((room) => (
            <article className="card" key={room?.name}>
              <Link to={`/rooms/${room?.name.replaceAll(" ", "-")}`} >
                <button className="btn-alt " style={{whiteSpace: "nowrap"}}>{t("more")}</button>
              </Link>
              <div>
                <h1 className="alt-font">{room?.name}</h1>
                <p>{room?.description}</p>
                <h2 className="alt-font">Prix : {room?.price} $</h2>

              </div>
              <div className="img-container">
                <Carousel
                  emulateTouch={true}
                  autoPlay={true}
                  infiniteLoop={true}
                  interval={5000}
                  showThumbs={false}
                  transitionTime={1000}
                >
                  {
                    room?.images.map((roomimage) => <div key={roomimage.toString()}>
                    <img  src={roomimage} style={{objectFit: 'cover'}} alt={room.name} />
                  </div>)
                  }
                </Carousel>
              </div>
              
            </article>
          ))
        )}
      </section>
      {/* {!serverStatus && <Loader />} */}
    </div>
  );
};

export default Rooms;
