import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { checkConnection, getSingleBooking } from "../../actions/booking";
import BookingWidget from "./BookWidget";
import Available from "./Available";
import Loader from "../helper/Loader";
import BookingHeader from "../../img/home/DSC06418.jpg";
import dateformat from "dateformat";
import "../../styles/Booking.scss";
import { useTranslation } from "react-i18next";
import roomData from "../pages/rooms/roomData";
import { toast } from "react-toastify";
import { newBooking } from "../../api";

const Reservation = (props) => {
  const history = useNavigate();
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [t, i18n] = useTranslation("global");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  // console.log(location, "booking")

  const [formData, setFormData] = useState({
    confirmation: "",
    email: "",
  });

  const [reservation, setReservation] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    adults: location?.state?.adults,
    children: location?.state?.children,
    dates: location?.state?.dates,
    title: "",
    totalprice: 0,
    price: 0,
    paymentType: "cash",
    cardNum: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleReservationChange = (e) => {
    setReservation({ ...reservation, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(selectedRoom === null){
      toast.warn("Selectionner une chambre d'abord! (Select a room first !)")
      return
    }

    const difftime = Math.abs(new Date(location?.state?.dates[0]) - new Date(location?.state?.dates[1]));
    const diffDays = Math.floor(difftime/(1000 * 60 * 60 * 24));
    if(diffDays < 0 || diffDays === NaN){
      toast.warn("Check in and check out incorrect !")
      return 
    }
    // console.log(location)
    // console.log(new Date(location?.states?.dates[0]) );
    // console.log(new Date(location?.states?.dates[1]));
    // console.log(Number(diffDays) * Number(selectedRoom.price));
    let new_booking ={...reservation, totalPrice: Number(diffDays) * Number(selectedRoom.price)}
    new_booking.totalPrice = Number(diffDays) * Number(selectedRoom.price);
    new_booking.title = selectedRoom?.name
    new_booking.price = Number(selectedRoom?.price)
    // console.log(new_booking);
    setLoading(true);
    const res = await newBooking(new_booking);
    if(res?.success){
      setLoading(false);
      toast.success("Booking complete ! ✅ \n Thank you for choosing Hotel Horizon");
      history("/");
      setReservation({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        adults: location?.state?.adults,
        children: location?.state?.children,
        dates: location?.states?.dates,
        title: "",
        totalprice: 0,
        price: 0,
        paymentType: "cash",
        cardNum: "",
      });
    }else{
      setLoading(false);

      toast.error(res?.message || "Erreur");
    }
  };

  return (
    <div className="Booking">
      <header
        className="header-main"
        style={{
          background: ` no-repeat center/cover url("${BookingHeader}")`,
        }}
      >
        <div className="header-content">
          <h2 className="alt-font">{t("reservation.title")}</h2>
          <p>{`check-in : ${dateformat(
            new Date(location?.state?.dates[0]),
            "dd/mm/yyyy"
          )} ~ check-out : ${dateformat(
            new Date(location?.state?.dates[1]),
            "dd/mm/yyyy"
          )}`}</p>
          <p>{`adults: ${location?.state.adults} , childrens: ${location?.state.children}`}</p>
        </div>
      </header>
      <section className="existing">
        <label>Booking</label>
        <form
          style={{
            display: "grid",
            justifyContent: "center",
            placeItems: "center",
            flexWrap: "wrap",
            gap: "8px",
          }}
        >
          <input
            style={{width: "100%"}}
            name="email"
            type="email"
            placeholder="email"
            value={reservation.email}
            onChange={handleReservationChange}
            required
          />
          <input
            style={{width: "100%"}}
            name="firstName"
            type="text"
            placeholder="first Name"
            value={reservation.firstName}
            onChange={handleReservationChange}
            required
          />
          <input
            style={{width: "100%"}}
            name="lastName"
            type="text"
            placeholder="last Name"
            value={reservation.lastName}
            onChange={handleReservationChange}
            required
          />
          <input
            style={{width: "100%"}}
            name="phone"
            type="text"
            placeholder="phone number"
            value={reservation.phone}
            onChange={handleReservationChange}
            required
          />
          {/* <input
            style={{width: "100%"}}
            name="firstName"
            type="text"
            placeholder="first Name"
            value={reservation.firstName}
            onChange={handleReservationChange}
          /> */}
          <div className="" style={{ display: "flex" }}>
            {roomData.map((item, index) => (
              <div
                className="hoverimagepic"
                onClick={()=>setSelectedRoom(item)}

                key={item.name}
                style={{
                  padding: "2px",
                  margin: "2px",
                  cursor: "pointer",
                  boxShadow:
                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                  background: item?.name===selectedRoom?.name ? "#83b0d4" : "transparent"
                }}
              >
                <img
                  style={{
                    maxWidth: "200px",
                    maxHeight: "200px",
                    objectFit: "cover",
                  }}
                  src={item.images[0]}
                  alt={item.name}
                />
                <div>
                  <h6>{item.name}</h6>
                  <h6>{item.price}$</h6>
                </div>
              </div>
            ))}
          </div>
          {/* <input
            style={{width: "100%"}}
            name="phone"
            type="text"
            placeholder="phone number"
            value={reservation.firstName}
            onChange={handleReservationChange}
          /> */}
          <select onChange={handleReservationChange} required style={{border: "1px solid black", borderRadius: "0px", fontWeight: "bold", minHeight: "40px"}} name="paymentType" value={reservation.paymentType}>
            <option value="cash">CASH | A L'ARRIVEE</option>
            <option value="card" disabled>CARD(VISA)</option>
            <option value="banking" disabled>BANKING</option>
            <option value="paypal" disabled>BANKING</option>
          </select>
          
          <button disabled={loading} onClick={handleSubmit} className="btn contrast">
            {loading ? " ... 🔃" : "BOOK"}
          </button>
        </form>
        {/* <span className="disclaimer">tracking * {t("reservation.info")}</span> */}
        {error.length > 0 && <span style={{ color: "red" }}>{error}</span>}
      </section>
      <br />
      <hr />
      <br />
      {/* <section className="existing">
        <label>{t("reservation.hint")}?</label>
        <form>
          <input
            style={{width: "100%"}}
            maxLength="6"
            name="confirmation"
            type="text"
            placeholder={t("reservation.input1")}
            value={formData.confirmation}
            onChange={handleReservationChange}
          />
          <input
            style={{width: "100%"}}
            name="email"
            type="text"
            placeholder={t("reservation.input2")}
            value={formData.email}
            onChange={handleReservationChange}
          />
          <button onClick={handleSubmit} className="btn contrast">
            {t("reservation.lookup")}
          </button>
        </form>
        <span className="disclaimer">tracking * {t("reservation.info")}</span>
        {error.length > 0 && <span style={{ color: "red" }}>{error}</span>}
      </section> */}
      <section className="desc">
        <h1 className="alt-font">{t("reservation.book")}</h1>
      </section>
      {/* <BookingWidget /> */}
    </div>
  );
};

export default Reservation;
