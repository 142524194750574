import SUIT1 from '../../../img/chambres/suit-1.jpg'
import SUIT2 from '../../../img/chambres/suit-2.jpg'
import SUIT3 from '../../../img/chambres/suit-3.jpg'
import SUIT4 from '../../../img/chambres/suit-4.jpg'
import SUIT5 from '../../../img/chambres/suit-5.jpg'
import SUIT6 from '../../../img/chambres/suit-6.jpg'
import SUIT7 from '../../../img/chambres/suit-7.jpg'
import NORMAL1 from '../../../img/chambres/normal-1.jpg';
import NORMAL2 from '../../../img/chambres/normal-2.jpg';
import NORMAL3 from '../../../img/chambres/normal-3.jpg';
import DOUBLE1 from '../../../img/chambres/double.jpg';
import TOILET from '../../../img/chambres/toilet.jpg';

const roomData = [
    {
        name: "Double-Bed Room",
        price: 105,
        images: [DOUBLE1, TOILET],
        description: "",
        amenities: []
    },
    {
        name: "Normal Room",
        price: 80,
        images: [NORMAL1, NORMAL2, NORMAL3, TOILET],
        description: "",
        amenities: []
    },
    {
        name: "Suit",
        price: 150,
        images: [SUIT1, SUIT2, SUIT3, SUIT4, SUIT5, SUIT6, SUIT7, TOILET],
        description: "",
        amenities: []
    }
]

export default roomData;