import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getRoom } from "../../../actions/rooms";
import BookWidget from "../../booking/BookWidget";
import { displayIcon } from "../../helper/Icons";
import "../../../styles/Room.scss";
import { useTranslation } from "react-i18next";
import roomData from "./roomData";
import { Carousel } from "react-responsive-carousel";
const Room = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currId = useParams()?.id;
  const [show, setShow] = useState(4);
  const [t, i18n] = useTranslation("global");
  const room = roomData.filter(
    (singleroom) => singleroom.name.replaceAll(" ", "-") === currId
  )[0];

  console.log(currId);
  console.log(room);

  const handleClick = () => {
    show === 4 ? setShow(room.amenities.length) : setShow(4);
  };
  return (
    <div className="Room">
      <header
        className="header-main"
        style={{
          background: `url(${room.images[0]})`,
          backgroundPosition: "center"
        }}
      >
        <div className="header-content">
          <h2 className="alt-font">{room?.name}</h2>
          <p>{room?.description}</p>
        </div>

        <BookWidget />
      </header>
      <section className="desc">
        <div className="img-container">
          <Carousel
            emulateTouch={true}
            autoPlay={true}
            infiniteLoop={true}
            interval={5000}
            showThumbs={false}
            transitionTime={1000}
          >
            {room?.images.map((roomimage) => (
              <div key={roomimage.toString()}>
                <img
                  src={roomimage}
                  style={{ objectFit: "cover" }}
                  alt={room.name}
                />
              </div>
            ))}
          </Carousel>
        </div>
        <h1 className="alt-font">{room.title}</h1>
        <p>{room.description}</p>
      </section>
      <section className="roomInfo">
        <div className="infoLeft">
          <div className="info">
            <div>
              <h3>{t("singleroom.view")}</h3>
              <p>Garden & Kivu Lake</p>
            </div>
            {/* <div>
              <h3>{t("singleroom.size")}</h3>
              <p>{room.size}</p>
            </div> */}
            <div>
              <h3>{t("singleroom.occupancy")}</h3>
              <p>
                {room.adults} {t("booking.adults")} & {room.children}{" "}
                {t("booking.childrens")}
              </p>
            </div>
            {/* <div>
              <h3>{t("singleroom.bedding")}</h3>
              <p>{room.bedding}</p>
            </div> */}
          </div>
          {room.amenities && (
            <div className="infoIcons">
              <h3>{t("singleroom.amenities")}</h3>
              <ul>
                {room.amenities
                  .filter((item, idx) => idx < show)
                  .map((item) => (
                    <li key={item}>
                      <i className={`${displayIcon(item)} itemIcon`}></i>
                      <p>{item}</p>
                    </li>
                  ))}
                <li onClick={handleClick}>
                  <i
                    className={`${
                      show <= 4 ? "fas fa-plus" : "fas fa-minus"
                    } itemIcon itemShow`}
                  >
                    {show <= 4 && <span>{room.amenities.length - 4}</span>}
                  </i>
                </li>
              </ul>
            </div>
          )}
        </div>

        <div className="infoRight">
          <h2 className="alt-font">{t("singleroom.overview")}</h2>
          <p>{room.description}</p>
        </div>
      </section>
      <section className="desc_main">
        <article className="descLeft">
          <div className="bg-light"></div>
          <h1 className="alt-font">{t("singleroom.title")}</h1>
          <p>{t("singleroom.desc")}</p>
        </article>
        <div className="descRight">
          <img src={room.images[0]} alt="sub_room" />
        </div>
      </section>
    </div>
  );
};

export default Room;
