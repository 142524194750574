import React, { Suspense, useEffect } from "react";
import "./styles/App.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import NavMobile from "./components/navbar/NavMoblile";
import Home from "./components/pages/Home";
import Rooms from "./components/pages/rooms/Rooms";
import Room from "./components/pages/rooms/Room";
import Dining from "./components/pages/Dining";
import Footer from "./components/pages/Footer";
import About from "./components/pages/About";
import Tour from "./components/pages/Tour";
import Booking from "./components/booking/Booking";
import Available from "./components/booking/Available";
import Checkout from "./components/booking/Checkout";
import Confirm from "./components/booking/Confirm";
import Auth from "./components/auth/Auth";
import Existing from "./components/booking/Existing";
import NoPage from "./components/pages/NoPage";
import { useMain } from "./context/main";
import LoaderAnimation from "./components/loading/index.js";
import EventPage from "./components/pages/event.js";
import "react-whatsapp-widget/dist/index.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import LoginPage from "./components/pages/Login.js";
import RegisterPage from "./components/pages/Register.js";
import ProfilePage from "./components/pages/profile.js";
import config from 'react-reveal/globals.js'
import AdminPage from "./components/pages/Admin.js";
import GalleryPage from "./components/pages/gallery.js";
import Reservation from "./components/booking/Reservation.js";
config({ssrFadeout: true});

const App = () => {
  const location = useLocation();
  const {pending} = useMain();
  /* const history = useNavigate(); */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
    <Suspense fallback={<LoaderAnimation/>}>
      {pending ? <LoaderAnimation/> : null}
      <Navbar />
      <NavMobile />
      {/* <h1>Hello there</h1> */}
      <Routes>
        <Route path="/" exact element={<Home/>} />
        <Route path="/rooms" exact element={<Rooms/>} />
        <Route
          path="/rooms/:id"
          
          element={<Room/>}
        />
        <Route path="/dining" exact element={<Dining/>} />
        <Route path="/about" exact element={<About/>} />
        <Route path="/events" exact element={<EventPage/>}/>
        <Route path="/tours" exact element={<Tour/>} />
        <Route path="/booking" exact element={<Booking/>} />
        <Route path="/reservation" exact element={<Reservation/>} />
        <Route path="/booking/availability" exact element={<Available/>} />
        <Route path="/booking/checkout" exact element={<Checkout/>} />
        <Route path="/booking/confirm" exact element={<Confirm/>} />
        <Route path="/booking/existing" exact element={<Existing/>} />
        <Route path="/login" exact element={<LoginPage/>} />
        <Route path="/register" exact element={<RegisterPage/>} />
        <Route path='/profile' exact element={<ProfilePage/>} />
        <Route path='/gallery' exact element={<GalleryPage/>} />

        <Route path="/admin" exact element={<Auth/>} />
        <Route path="/admin/panel" element={<AdminPage/>} />

        <Route path="*" exact element={<NoPage/>} />
      </Routes>   
      <Footer />      
    </Suspense>
    <ToastContainer/>
      <WhatsAppWidget
          // CompanyIcon={IMFLogo}
          replyTimeText="Répond généralement dans 24hr"
          message="Bonjour! 👋🏼 Que pouvons-nous faire pour vous ?"
          companyName="Hotel Horizon"
          SendButton="Envoyer"
          phoneNumber="+243994406270"
        />
    </>
  );
};

export default App;
