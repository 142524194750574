import React from "react";
import "../../styles/Footer.scss";
import Slide from 'react-reveal/Slide';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [t, i18n] = useTranslation("global")
  return (
    <Slide bottom>
      <div className="Footer">
      <div>
        <h2 className="alt-font">Hotel Horizon</h2>
      </div>
      <div>
        <ul>
          {/* <li>
            <Link to="/login">{t("auth.login")}</Link>
          </li> */}
          <li style={{margin: "10px"}}>
          <b>Adresse:</b> Avenue Muhumba N°24, Ibanda, Bukavu / Sud-Kivu / RDCongo
          </li>
          <li>
            <a href='mailto:hotelhorizonbkv@gmail.com'>hotelhorizonbkv@gmail.com</a>
          </li>
          <li>
            <span>+243 99 440 6270</span>
            <span>+243 85 305 4302</span>
          </li>
        </ul>
      </div>
      <div>
        <p>{new Date(Date.now()).getFullYear()} © HOTEL HORIZON. ALL RIGHTS RESERVED.</p>
      </div>
    </div>
    </Slide>
  );
};

export default Footer;
