import React from "react";
import "../../styles/Tour.scss";
import BookWidget from "../booking/BookWidget";
import { useTranslation } from "react-i18next";
import BGImage from '../../img/tours/mike-arney-rJ5vHo8gr2U-unsplash.jpg';
import BukavuLake from '../../img/tours/bk3.jpg';
import NightLightBukavu from '../../img/tours/bk2.jpg';
import BukavuView from '../../img/tours/bk6.jpg';
import BukavuMonument from '../../img/tours/bk7.jpg'
import Fade from 'react-reveal/Fade'
import { Carousel } from "react-responsive-carousel";

const Tour = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className="Tour">
      <Fade>
      <header
        className="header-main"
        style={{
          background:
            ` no-repeat center/cover url(${BGImage})`,
        }}
      >
        <div className="header-content">
          {/* <h2 className="alt-font">{t("tourism.title")}</h2>
          <p>{t("tourism.sub")}</p> */}
        </div>

        <BookWidget />
      </header>
      </Fade>
      <Fade top>
      <section className="desc">
        <h1 className="alt-font">{t("tourism.subtitle")}</h1>
        <p>
          {t("tourism.desc")}
        </p>
      </section>
      </Fade>
      <Fade bottom>
      <section className="desc_sec">
        <div className="img-container">
        <Carousel
            emulateTouch={true}
            autoPlay={true}
            infiniteLoop={true}
            interval={5000}
            showThumbs={false}
            transitionTime={300}
          >
            <div className="">
              <img src={BGImage} alt="markets" />
            </div>
            <div>
              <img src="https://images.unsplash.com/photo-1576526164505-9a2540866186?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8a2FodXppfGVufDB8fDB8fHww" alt="gorille image"/>
            </div>
            <div>
              <img src="https://cdn.wcs.org/2022/05/24/jh3i6y7f1_grauer_s_gorilla_with_baby_CREDIT_Andrew_Plumptre_2_.jpg" alt="image gorille"/>
            </div>
          </Carousel>
        </div>
      
        
        <article>
          <span></span>
          <div>
            <h1 className="alt-font">{t("tourism.title2")}</h1>
            <p>
              {t("tourism.desc2")}
            </p>
          </div>
          <span></span>
        </article>
      </section>
      </Fade>
      <Fade bottom>
      <section className="desc_gallery">
        <div className="descLeft">
          {/* <h2 className="alt-font">{t("tourism.title3")}</h2> */}
          <div className="img-container">
            <img
              className="lg-img"
              src={BukavuMonument}
              alt="island_tour"
            />
          </div>
        </div>
        <div className="descRight">
          <img src={NightLightBukavu} alt="island_tour_01" />
          {/* <article>
            <h2 className="alt-font">Bukavu / Sud-Kivu / RDCongo</h2>
            <p>
              {t("tourism.desc3")}
            </p>
          </article> */}
        </div>
      </section>
      </Fade>
    </div>
  );
};

export default Tour;
