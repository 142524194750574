import React from "react";
import "../../styles/About.scss";
import BookWidget from "../booking/BookWidget";
import { useTranslation } from "react-i18next";
import AboutBG from '../../img/home/Horizon_39.jpg';
import AboutImg2 from '../../img/home/DSC06567.jpg';
import ContactSection from "../contact";
import Fade from 'react-reveal/Fade'

const About = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className="About">
      <Fade>
      <header className='header-main'
        style={{
          background:
            `no-repeat center/cover url(${AboutBG})`,
        }}
      >
        <div className="header-content">
          <h2 className="alt-font">{t("info.title")}</h2>
          <p>Welcome</p>
        </div>

        <BookWidget />
      </header>
      </Fade>
      <Fade bottom>
      <section className="desc">
        <h1 className="alt-font">Hotel Horizon</h1>
        <span></span>
        <p>{t("event.title")}</p>
      </section>
      </Fade>
      <Fade top>
      <section className="desc_photo">
        <div className="descLeft">
          <div>
            <h1 style={{zIndex: "999"}} className="alt-font">{t("info.title1")}</h1>
            <p>
              {t("info.desc1")}
            </p>
          </div>
        </div>
        <div className="descRight">
          <img src={AboutImg2} alt="dining_bar" />
        </div>
      </section>
      </Fade>
      <ContactSection/>
      {/* <section className="desc_photo">
        <div className="descLeft">
          <h1 className="alt-font">{t("info.title1")}</h1>
          <p>
            {t("info.desc1")}
          </p>
        </div>
        <div className="descRight">
          <img src="/img/about/about_farmer.jpg" alt="about_farmer" />
        </div>
      </section> */}
      
    </div>
  );
};

export default About;
