import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router} from 'react-router-dom'
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import {thunk} from 'redux-thunk';
import App from "./App";
import global_en from './translations/en/global.json';
import global_fr from './translations/fr/global.json';
import i18next from 'i18next';
import {I18nextProvider} from 'react-i18next';
import "./styles/index.scss";

import reducers from "./reducers";
import MainProvider from "./context/main";

i18next.init({
  interpolation: {escapeValue: false},
  lng: "fr",
  resources: {
    fr: {
      global: global_fr
    },
    en: {
      global: global_en
    }
  }
})

const store = createStore(reducers, compose(applyMiddleware(thunk)));

ReactDOM.render(
  // Provider connects the store (global states) to the entire App
  <Provider store={store}>
    <Router>
      <MainProvider>
      <I18nextProvider i18n={i18next}>
          <App />
      </I18nextProvider>
      </MainProvider>
    </Router>  
  </Provider>,
  document.getElementById("root")
);
