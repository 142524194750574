import React, { useState, useEffect } from "react";
import * as api from "../../api/index";
import Bookings from "./bookings/Bookings";

import "./Auth.scss";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Auth = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [password, setPassword] = useState("")
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("horizonUser"))); // profile is being access from local storage, shich was set in the reducer file auth.js

  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(user);
    if(user?.role !== "admin" && user?.role !== "dev"){
      // console.log(user);
      navigate("/")
      return toast.warn("Pas d'autorisation!")
    }
    if(password === ""){
      return toast.warn("Entrez votre mot de passe!")
    }
    if(password !== process.env.REACT_APP_ADMIN_PASSWORD){
      return toast.warn("Mot de passe Incorrecte")
    }
    sessionStorage.setItem("admin-access", JSON.stringify({isAdmin: true}))
    toast.success("Bienvenue")
    navigate("/admin/panel")
  };
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    setUser(null);
  };

  useEffect(()=>{
    if (user?.role !== 'admin' && user.role !== "dev"){
      toast.warn("Pas d'autorisation!")
      navigate("/")
    }
    let isadmin = sessionStorage.getItem("admin-access");
    if(JSON.parse(isadmin)?.isAdmin){
      navigate("/admin/panel");
    }
  }, []);

  return (
    <div className="Auth">
      <header
        className="header-main"
        style={{
          background:
            ' no-repeat center/cover url("/img/admin/admin_main.jpg")',
        }}
      >
        <div className="header-content">
          <h2 className="alt-font">Admin Panel</h2>
        </div>
      </header>
      <div className="login">
          <h1 className="alt-font">Admin Login</h1>
          <span style={{ color: "red" }}>{error.length > 0 && error}</span>
          <form onSubmit={handleSubmit}>
            <input
              type="password"
              value={password}
              placeholder="Password"
              name="password"
              onChange={(e)=>setPassword(e.target.value)}
            />
            <button type="submit" className="btn">Continue</button>
          </form>
        </div>
    </div>
  );
};

export default Auth;
