import React from "react";
import "../../styles/Dining.scss";
import { useTranslation } from "react-i18next";
import DiningImage from "../../img/dining/Horizon_56.jpg";
import DiningImage2 from "../../img/dining/Horizon_60.jpg";
import DiningBar from "../../img/dining/Horizon_54.jpg";
import DiningBar2 from "../../img/dining/Horizon_55.jpg";
import Fade from 'react-reveal/Fade';

const Dining = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="Dining">
      <Fade top>
      <header
        className="header-main"
        style={{
          background: ` no-repeat center/cover url(${DiningImage})`,
        }}
      >
        <div className="header-content">
          <h2 className="alt-font">{t("dining.title")}</h2>
          {/* <p>{t("dining.sub")}</p> */}
        </div>
      </header>
      </Fade>
      <Fade>
      <section className="desc">
        <h1 className="alt-font">Cuisine</h1>
        <p>
          {t("dining.desc")}
        </p>
      </section>
      </Fade>
      <div className="container" style={{display: "flex", justifyContent: 'center', margin: "20px"}}>
        <iframe src="https://restaurant-menu-horizon-website.vercel.app/" width="90%" height="700px"></iframe>
      </div>
      {/* <Fade top>
      <section className="desc_gallery">
        <div className="descLeft">
          <h2 className="alt-font">{t("dining.title2")}</h2>
          <div className="img-container">
            <img src={DiningImage2} alt="dining_pic" />
          </div>
        </div>
        <div className="descRight">
          <img src={DiningBar2} alt="dining_team" />
          <article>
            <h2 className="alt-font">{t("dining.title1")}</h2>
            <p>
              {t("dining.desc1")}
            </p>
          </article>
        </div>
        </section>
      </Fade>
      
      <Fade bottom>
      <section className="desc_photo">
        <div className="descLeft">
          <div>
            <h1 className="alt-font">{t("dining.title3")}</h1>
            <p>
              {t("dining.desc2")}
            </p>
          </div>
        </div>
        <div className="descRight">
          <img src={DiningBar} alt="dining_bar" />
        </div>
      </section>
      </Fade> */}
    </div>
  );
};

export default Dining;
