import React from "react";
import "../../styles/About.scss";
import BookWidget from "../booking/BookWidget";
import { useTranslation } from "react-i18next";
import EventBG from '../../img/home/Horizon_1.jpg'
import EventImage from '../../img/home/Horizon_3.jpg'
import Fade from 'react-reveal/Fade';
import GardenImage from '../../img/home/Horizon_5.jpg';
import EventBookingSection from "../eventBooking";

const EventPage = () => {
    const [t, i18n] = useTranslation("global");
  return (
    <div className="About">
      <Fade top>
      <header className='header-main'
        style={{
          background:
            ` no-repeat center/cover url(${EventBG})`,
        }}
      >
        <div className="header-content">
            <h2 className="alt-font">{t("header.links.event")}</h2>
          <p>{t("about.spotlight")}</p>
        </div>

        {/* <BookWidget /> */}
      </header>
      </Fade >
      <Fade top>
      <section className="desc">
        <h1 className="alt-font">Hotel Horizon</h1>
        <span></span>
        <p>{t("event.title")}</p>
      </section>
      </Fade>
      <Fade bottom>
      <section className="desc_photo">
        <div className="descLeft">
          <h1 className="alt-font" style={{zIndex: "20"}}>{t("event.title1")}</h1>
          <p>
            {t("event.desc")}
          </p>
        </div>
        <div className="descRight">
          <img src={EventImage} alt="about_farmer" />
        </div>
      </section>
      </Fade>
      <Fade bottom>
      <section className="desc_photo">
        <div className="descLeft">
          <h1 className="alt-font">{t("event.gardenTitle")}</h1>
          <p>
            {t("event.gardenDesc")}
          </p>
        </div>
        <div className="descRight">
          <img src={GardenImage} alt="about_farmer" />
        </div>
      </section>
      </Fade>
      <Fade bottom>
        <EventBookingSection/>
      </Fade>
    </div>
  );
};

export default EventPage;
