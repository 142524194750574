import React from 'react'
import Fade from 'react-reveal/Fade'
import Slide from 'react-reveal/Slide'

import Home38 from '../../img/home/Horizon_38.jpg'

import Image1 from '../../img/home/DSC06418.jpg';
import Image2 from '../../img/home/DSC06539-HDR.jpg';
import Image3 from '../../img/home/DSC06544.jpg';
import Image4 from '../../img/home/DSC06552-HDR.jpg';
import Image5 from '../../img/home/DSC06567.jpg';
import Image6 from '../../img/home/DSC06575.jpg';
import Image7 from '../../img/home/Horizon_12.jpg';
import Image8 from '../../img/home/Horizon_12.jpg';
import Image9 from '../../img/home/Horizon_47.jpg';
import Image10 from '../../img/home/Horizon_37.jpg';


import { useTranslation } from 'react-i18next';

function GalleryPage() {
  const [t, i18n] = useTranslation("global");
  const images = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,

  ]
    return (
        <div>
            <div className="About">
                <Fade>
                <header className='header-main'
                    style={{
                    background:
                        `no-repeat center/cover url(${Home38})`,
                    }}
                >
                    <div className="header-content">
                    <h2 className="alt-font">{t("header.links.gallery")}</h2>
                    <p>Welcome</p>
                    </div>

                </header>
                </Fade>

                <Slide bottom>
                    <div style={{ margin: "8px", display: 'flex', gap: "4px", justifyContent: 'space-around', flexWrap: 'wrap', padding: "0 4px"}}>
                        {
                            images.map((item, idx ) => {
                                return <img key={idx} src={item} style={{maxWidth: "400px", objectFit: "cover"}} />
                            })
                        }
                    </div>
                </Slide>
                    
      </div>
      
    </div>
    )
}

export default GalleryPage
