import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import LoginBG from '../../img/home/DSC06544.jpg'
import { Link, useNavigate } from 'react-router-dom';
import { register } from '../../api';
import { toast } from 'react-toastify';
import { useMain } from '../../context/main';

function RegisterPage() {
    const [t, i18n] = useTranslation("global");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const {setPending, pending} = useMain();

    const Register = async (e) => {
        e.preventDefault();
        setPending(true);
        const res = await register({username, email, password})

        if(res){
            toast.success(res);
            setPending(false);

            navigate("/login")
        }else{
            setPending(false);
            toast.error("Erreur, Verifier votre connexion")
        }
    }

    useEffect(() => {
        const user = sessionStorage.getItem("horizonUser");
        if(user)
            navigate("/profile")
    }, []);

    return (
        <div>
            <Fade>
      <header className='header-main'
        style={{
          background:
            `no-repeat center/cover url(${LoginBG})`,
          maxHeight: "300px"
        }}
      >
        <div className="header-content">
          <h2 className="alt-font">Welcome</h2>
          
        </div>

      </header>
      </Fade>
        
        <div style={{display: "flex", margin: "20px", justifyContent: 'center', alignContent: "center"}}>
            <div className="login-form-box" style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px"}}>
            <form onSubmit={Register} className="form">
                <span className="title" style={{fontSize: "30px"}}>{t("auth.register")}</span>
                <span className="subtitle" style={{fontSize: "20px"}}>Welcome to Horizon hotel.</span>
                <div className="form-container">
                <input value={username} style={{fontSize: "16px", marginBlock: "8px", }} onChange={(e) => setUsername(e.target.value)} type="text" className="input" placeholder={t("auth.username")}/>
                <input value={email} style={{fontSize: "16px", marginBlock: "8px", }} onChange={(e) => setEmail(e.target.value)} type="email" className="input" placeholder={t("auth.email")}/>
                <input value={password} style={{fontSize: "16px", marginBlock: "8px", }} onChange={(e) => setPassword(e.target.value)} type="password" className="input" placeholder={t("auth.password")}/>
                </div>
                <button disabled={pending} type="submit">{t("auth.confirm")}</button>
            </form>
            <div className="form-section">
            <p fontSize={{fontSize: "16px"}}>{t("auth.already")}<Link to="/login">{t("auth.login")}</Link> </p>
            </div>
            </div>
        </div>
        </div>
    )
}

export default RegisterPage
