import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import ProfileBackground from "../../img/tours/DSC01722.jpg";
import { useMain } from "../../context/main";
import { toast } from "react-toastify";
import AdminPage from "./Admin";
import { getBooking, getMyBooking } from "../../api";
import "../../styles/Booking.scss";
import roomData from "./rooms/roomData";
import dateFormat from "dateformat";

function ProfilePage() {
  const [t, i18n] = useTranslation("global");
  const [user, setLocalUser] = useState({});
  const { setUser } = useMain();
  const navigate = useNavigate();
  const statuscolors = {
    "pending": "#808080",
    "confirmed": "#3c9966", 
    "client-canceled": "#eb4848", 
    "canceled": "#e93e27" 
  }
  const [booking, setBooking] = useState(null);
  const [formData, setFormData] = useState({
    confirmation: "",
    email: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // console.log("Hello my friend")

  const Deconnecting = () => {
    localStorage.removeItem("horizonUser");
    setUser(null);
    toast("Deconnected");
    navigate("/");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchSingleBooking();
  }

  

  const fetchSingleBooking = async () => {
    const res = await getBooking(formData.confirmation.toUpperCase());
    if(res?.success){
      setBooking(res?.booking);
      console.log(res.booking);

    }else{
      toast.error(res?.message || "Erreur, Not found (Pas disponible)")
    }
  }

  useEffect(() => {
    const localUser = localStorage.getItem("horizonUser");
    // console.log(localUser);
    if (!localUser) {
      navigate("/login");
      localStorage.clear();
    } else {
      setLocalUser(JSON.parse(localUser));
      // console.log(JSON.parse(localUser)?.email);
      // JSON.parse()
    }
  }, []);

  return (
    <div className="Booking">
      <Fade top>
        <header
          className="header-main"
          style={{
            background: ` no-repeat center/cover url(${ProfileBackground})`,
          }}
        >
          <div className="header-content">
            <h2 className="alt-font">PROFILE</h2>
            <p>HOTEL HORIZON</p>
          </div>
        </header>
      </Fade>
      <Fade>
        <section className="desc">
          <h2 className="alt-font">{user?.username}</h2>
          <h2 className="alt-font">{user?.email}</h2>
          <br />
          <hr />
          <div>
          <section className="existing">
        <label>{t("reservation.hint")}?</label>
        <form>
          <input
            style={{width: "100%"}}
            maxLength="6"
            name="confirmation"
            type="text"
            placeholder={t("reservation.input1")}
            value={formData.confirmation}
            onChange={handleChange}
            required
          />
          <button onClick={handleSubmit} className="btn contrast">
            {t("reservation.lookup")}
          </button>
        </form>
        <span className="disclaimer">tracking * {t("reservation.info")}</span>
        {/* {error.length > 0 && <span style={{ color: "red" }}>{error}</span>} */}
        <br/>
        <br/>
        {
          booking && 
            <div style={{display: "flex", justifyContent: "center"}}>
              <article className="card">
                <div className="">
                  <ul>
                    <li>check-in: {dateFormat(new Date(booking?.startDate), "dd/mm/yyyy")}</li>
                    <li>check-out: {dateFormat(new Date(booking?.endDate), "dd/mm/yyyy")}</li>
                    <li>room: {booking?.room}</li>
                    <li>adults : {booking?.adults} ~ children: {booking?.children}</li>
                    <li>price: {booking?.price} ~ total: {booking?.totalPrice}</li>
                    <li><b>{booking?.cardType}</b></li>
                  </ul>
                </div>

                <div style={{margin: "8px", }}>
                  <div >
                    <span style={{fontSize: "12px"}}>TRACKING-ID</span>
                    <h4 style={{textAlign: "center",fontSize: "18px", textDecoration: "underline"}}> {booking?.confirmation}</h4>
                  </div>
                  <span className="" style={{border: "1px solid black", paddingInline: "8px", color:"black", fontWeight: "bold", borderRadius: "10px", backgroundColor: statuscolors[booking?.status]}}
                    >{booking?.status}</span
                  >
                </div>
              </article>
            </div>
          
        }
      </section>
      </div>
          
          <br />
          {(user.role === "dev" || user.role === "admin") && (
            <button
              className="btn"
              style={{
                background: "#509450",
                borderRadius: "20px",
                color: "white",
                fontWeight: "bold",
                margin: "10px",
              }}
              onClick={() => navigate("/admin")}
            >
              Admin
            </button>
          )}
          <br />
          <br />
          <hr />
          <button
            className="btn"
            style={{
              background: "crimson",
              color: "white",
              fontWeight: "bold",
              margin: "10px",
              borderRadius: "20px",
            }}
            onClick={Deconnecting}
          >
            Deconnect
          </button>
        </section>
      </Fade>
      <div>
        {/* <AdminPage /> */}
      </div>
    </div>
    
  );
}

export default ProfilePage;
